<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-ground-banner with-route-bar">
      <div class="items">
        <div
          class="item"
          :style="{
            'background-image': `url(${require('@/assets/img/fo/bg-4.jpg')})`
          }"
        >
          <span v-html="$t('public.service_header')"></span>
        </div>
      </div>
    </section>

    <section class="section section-inner">
      <div class="container m-container-only-s">
        <div class="content-cols">
          <div class="left-col">
            <img src="@/assets/img/hero-steward.png" alt="" />
            <p>
              {{ $t("public.service_text_1") }}
            </p>
          </div>
          <div class="legals">
            <p>{{ $t("public.service_text_2") }}.</p>
          </div>
          <div class="right-col">
            <h1>{{ $t("public.service_text_3") }}<span class="sup">*</span></h1>

            <p>{{ $t("public.service_text_4") }}</p>
            <p>{{ $t("public.service_text_5") }}</p>

            <ol>
              <li>
                <h3>{{ $t("public.service_text_6") }}</h3>
                <p>
                  {{ $t("public.service_text_7") }}
                  <img src=" @/assets/i/nav-red.svg" alt="" class="nav-icon" />
                </p>
                <img
                  src="@/assets/img/steward-img-1.png"
                  class="screen"
                  alt=""
                />
                <p>
                  {{ $t("public.service_text_8") }}
                </p>
              </li>
              <li>
                <h3>{{ $t("public.service_text_9") }}</h3>
                <img
                  src="@/assets/img/steward-img-2.png"
                  class="screen small"
                  alt=""
                />
              </li>
              <li>
                <h3>{{ $t("public.service_text_10") }}</h3>
                <p>
                  {{ $t("public.service_text_11") }}
                  <img
                    src="@/assets/i/icon-clock.svg"
                    alt=""
                    class="nav-icon"
                  />
                  {{ $t("public.service_text_12") }}
                </p>
                <img
                  src="@/assets/img/steward-img-3.png"
                  class="screen"
                  alt=""
                />
                <p>
                  {{ $t("public.service_text_13") }}
                </p>
              </li>
              <li>
                <h3>
                  {{ $t("public.service_text_2") }}
                </h3>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";
import flight from "@/mixins/flight";

export default {
  name: "ServicesPublic",
  mixins: [cover, flight],
  title() {
    return this.$t("pages.services");
  },
  computed: {
    ...mapState({
      orders: state => state.order.index,
      waiting: state => state.order.waiting,
      ordersLoading: state => state.order.indexLoading,
      layout: state => state.order.layout,
      layoutLoading: state => state.order.layoutLoading,
      showModal: state => state.order.showModal,
      authenticate: state => state.user.authenticate,
      isUpgrade: state => state.flight.isUpgrade
    }),
    ...mapState({
      locale: state => state.locale
    })
  },
  methods: {
    ...mapActions("order", {
      actionIndex: "index",
      setOrder: "setOrder",
      actionLayout: "layout",
      cancelOrder: "cancelOrder"
    }),
    ...mapMutations("order", {
      setEntity: "SET_ENTITY"
    })
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
